// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo-stardust.jpg'; // Replace with the actual path to your logo image

function Header() {
    return (
        <header className="App-header" style={{ display: 'flex', alignItems: 'center', padding: '10px 20px', backgroundColor: '#f8f9fa' }}>
            <Link to="/">
                <img src={logo} alt="Logo" style={{ height: '50px' }} />
            </Link>
            <h1 style={{ marginLeft: '20px', color: '#333' }}>Welcome to the Product Store</h1>
        </header>
    );
}

export default Header;
